import React from 'react'
import Layout from '../../components/layout/layout'
import { Link } from 'gatsby'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import nasaIcon from '../../images/logos/nasa_logo.svg'


const IndexPage = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="Carbon Connections - The Carbon Cycle and the Science of Climate" />
    <CCBreadcrumb
      pathname={props.location.pathname}
      title={'About'}
      replace={props.replace}
    />
    <Container fluid className="mb-5">
      <Row className="d-flex">
        <Col>
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <Card>
                    <Card.Header className="cardHeader">
                      <h1 className="headerFontBlue">About Carbon Connections</h1>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <Row>
                        <Col>
                          <div className="nasaIcon">
                            <img
                              className="image image-bordered img-fluid"
                              src={ nasaIcon }
                              alt="NASA icon"
                            />
                            <div className="image-caption image-caption-bordered">
                              Funding for <em>Carbon Connections</em> was provided by <a href="https://www.nasa.gov/" target="_blank" rel="noopener noreferrer">The National Aeronautics and Space Administration&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a> (NASA Award # NNX10AB56A).
                            </div>
                          </div>

                          <p>
                            <em>Carbon Connections</em> is an inquiry-based, three-unit, online <Link to="../introduction-to-units">curriculum</Link> for students in grades 9-12. The course was designed to improve students' understanding of the carbon cycle and the science of Earth's climate. Each unit includes five lessons. The concepts covered in the lessons span all science disciplines.
                          </p>

                          <p>
                            The three units follow carbon in past, present, and future contexts. Each lesson is designed to engage students by allowing them to manipulate NASA data in a controlled, online environment. This will enhance their understanding of the carbon cycle and the science of global climate change.
                          </p>

                          <p className="lead">Project Goals and Intended Outcomes</p>
                          <p>
                            The goals of the project are to help teachers improve student understanding of:
                          </p>
                          <ul>
                            <li>Scientific concepts related to carbon cycling and decisions addressing climate change</li>
                            <li>The processes and nature of science, including using models to analyze systems</li>
                            <li>NASA's contributions to our understandings of climate change and related issues</li>
                          </ul>

                          <p>
                            The project also aims to expose students to NASA, and generate interest in NASA careers as well as professions in allied STEM (science, technology, engineering, and math) fields.
                          </p>

                          <p>
                            The anticipated outcomes of <em>Carbon Connections</em> are:
                          </p>
                          <ul>
                            <li>Better understandings of the carbon cycle and climate science by all students and teachers</li>
                            <li>Better understanding of the vital role of NASA in monitoring the Earth system</li>
                            <li>Increased interest in science and careers in STEM fields</li>
                            <li>Better ability of student-citizens to make complex decisions about climate change</li>
                          </ul>

                          <p className="lead">Course Components</p>
                          <p>
                            The <em>Carbon Connections</em> website materials were designed specifically for students in grades 9-12. The course integrates content through a variety of media (text, animation, video, and interactive graphs) to support different teaching methods and learning styles.
                          </p>

                          <p>
                            The multimedia components bring to life the concept of carbon cycling and climate science, helping students to better visualize and understand the Earth system. Being able to manipulate data and models also helps students understand the nature of science and the important role of evidence as they investigate data in the classroom.
                          </p>

                          <p className="lead">Teacher Resources</p>
                          <p>
                            On the dedicated and secure <Link to="../teacher-support">Teacher Resources </Link>page, teachers will find resources such as background materials, advance preparation information, materials lists for each activity, handouts, discussion PowerPoint<sup>&reg;</sup> presentations, assessments, and short videos with descriptions and tips on how to set up some of the hands-on activities for your students.
                          </p>

                          <p>
                            For more information about <em>Carbon Connections</em>, please contact <a href="mailto:info@bscs.org" target="_blank" rel="noopener noreferrer">info@bscs.org&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a> or visit the BSCS website at <a href="https://bscs.org" target="_blank" rel="noopener noreferrer">www.bscs.org&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a>.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
